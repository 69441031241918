<template>
  <v-container class="form__container" fluid>
    <v-row
      align="center"
      :class="showError ? 'employee-form__employees-panel-error' : ''"
    >
      <v-col cols="12">
        <h6 class="text-h6">
          {{ $trans("service_employee_title") }}
        </h6>
      </v-col>
      <template v-if="activeEmployees.length === 0">
        <v-col cols="12" class="text-center">
          <p class="text-body-1" v-text="$trans('no_employees_found')" />
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn color="blue" depressed dark @click="openNewEmployeeDialog">
            {{ $trans("add_employee") }}
          </v-btn>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12">
          <v-checkbox
            v-model="selectAllEmployeesCheckboxValue"
            :disabled="disabled"
            hide-details
            :label="$trans('all_employees')"
            @change="selectAllEmployeesCheckboxOnChange"
          />
        </v-col>
        <v-col
          v-for="item in list"
          :key="item.id"
          lg="4"
          md="6"
          sm="6"
          cols="12"
        >
          <v-card elevation="0" class="mx-auto" max-width="400">
            <v-card-actions>
              <v-list-item class="grow">
                <v-list-item-avatar
                  v-if="getImage(item.user.default_image)"
                  size="48"
                >
                  <v-img :src="getImage(item.user.default_image)" />
                </v-list-item-avatar>
                <v-row class="line ml-1" align="center" justify="space-between">
                  <v-list-item-content>
                    <v-list-item-title class="text-body-1 text-wrap">
                      {{ `${item.user.name} ${item.user.surname}` }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption">
                      {{ item.title | truncate(30) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-switch
                    v-model="item.isSelected"
                    :disabled="disabled"
                    color="secondary"
                    class="ml-2 mr-1"
                    @change="updateEmployees"
                  />
                </v-row>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn color="blue" depressed dark @click="openNewEmployeeDialog">
            {{ $trans("add_employee") }}
          </v-btn>
        </v-col>
      </template>
    </v-row>
    <v-row v-if="showError">
      <v-col class="pt-0 mt-0">
        <span class="v-messages error--text">{{
          $trans("select_employee_error")
        }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import planActions from "@/calendesk/mixins/planActions";

export default {
  name: "ServiceEmployeePanel",
  mixins: [planActions],
  props: {
    currentEmployees: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
      selectAllEmployeesCheckboxValue: false,
      addedEmployees: [],
    };
  },
  computed: {
    ...mapGetters({
      activeEmployees: "employee/getActiveEmployees",
    }),
  },
  watch: {
    currentEmployees(val) {
      val && this.reloadSelected();
    },
    "getDialog.open": function () {
      if (this.getDialog.data && this.getDialog.data.reloadEmployee === true) {
        this.addedEmployees.push(this.getDialog.data.employeeId);
        this.closeDialog();
        this.reloadSelected();
      }
    },
    list(val) {
      if (val && val.length > 0) {
        const selected = val.filter((employee) => employee.isSelected);

        this.selectAllEmployeesCheckboxValue =
          selected.length === this.activeEmployees.length;
      }
    },
  },
  created() {
    this.fetchActiveEmployees().then(() => {
      this.reloadSelected();
    });
  },
  methods: {
    ...mapActions({
      fetchActiveEmployees: "employee/fetchActive",
    }),
    selectAllEmployeesCheckboxOnChange(val) {
      this.list.forEach((el) => {
        el.isSelected = val;
      });
      this.updateEmployees();
    },
    updateEmployees() {
      const selected = this.list.filter((x) => x.isSelected);
      this.$emit(
        "employees-data",
        selected.map((x) => x.id)
      );

      this.selectAllEmployeesCheckboxValue =
        selected.length === this.activeEmployees.length;
    },
    getImage({ name }) {
      return name ? `${this.$config("image_base_url")}${name}` : false;
    },
    openNewEmployeeDialog() {
      if (this.canAddEmployees) {
        this.openDialog({
          type: dialogTypes.ADD_NEW_EMPLOYEE,
          size: dialogSize.SMALL,
          title: this.$trans("add_employee"),
          ctaButtonAction: "handleSave",
          ctaButtonIcon: "$plus-circle",
          ctaButtonTitle: this.$trans("add"),
          persistent: true,
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    reloadSelected() {
      const current = this.currentEmployees;

      const alreadySelected = this.list.map((e) => {
        if (e.isSelected) {
          return e.id;
        }
      });

      this.list = this.activeEmployees.map((x) => {
        let isSelected = current.length
          ? !!current.find((el) => el.id === x.id)
          : undefined;

        if (!isSelected) {
          isSelected =
            this.selectAllEmployeesCheckboxValue ||
            alreadySelected.includes(x.id) ||
            this.addedEmployees.includes(x.id);
        }

        return {
          id: x.id,
          user: x.user,
          title: x.title,
          isSelected,
        };
      });

      this.updateEmployees();
    },
  },
};
</script>
<style lang="scss" scoped>
.line {
  border-bottom: 1px solid rgba(38, 50, 56, 0.24);
}
.employee-form__employees-panel-error {
  border: solid 2px var(--v-error-base);
  border-radius: 6px;
}
</style>
